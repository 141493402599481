<template>
    <div class="qingwu">
        <el-form label-width="100px" ref="info" :model="info">
            <!--<el-form-item label-width="160px" label="客服电话" prop="客服电话"
                          :rules="[{required:false,message:'客服电话不能为空',trigger: 'blur' }]">
                <el-input placeholder="请输入客服电话" v-model="info.lianxdh"></el-input>
            </el-form-item>
            <el-form-item label-width="160px" label="邮箱" prop="邮箱"
                          :rules="[{required:false,message:'邮箱不能为空',trigger: 'blur' }]">
                <el-input placeholder="请输入邮箱" v-model="info.youx"></el-input>
            </el-form-item>-->
            <el-form-item label="支付宝" prop="logo">
                <el-upload class="avatar-uploader" :action="$api.logoUpload" :headers="upload_headers"
                           :show-file-list="false" :on-success="handleAvatarSuccess">
                    <img v-if="info.kefwx" :src="info.kefwx" class="avatar-upload">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <!--<el-form-item label-width="160px" label="APPSECRET" prop="appsecret" :rules="[{required:true,message:'APPSECRET不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.appsecret"></el-input></el-form-item>
            <el-form-item label-width="160px" label="MCH_ID" prop="mchid" :rules="[{required:true,message:'MCH_ID不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.mchid"></el-input></el-form-item>
            <el-form-item label-width="160px" label="KEY" prop="key" :rules="[{required:true,message:'KEY不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.key"></el-input></el-form-item>-->
            <el-form-item label-width="160px" label="备注：">
                <el-tag type="info">支付宝收款码</el-tag>
            </el-form-item>
            <el-form-item label-width="160px">
                <el-button type="primary" @click="submitForm('info')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                info: {},
                upload_headers: {},
                kefwx: ''
            };
        },
        watch: {},
        computed: {},
        methods: {
            submitForm: function (e) {
                this.$refs[e].validate(res => {
                    if (res) {
                        // Http 请求
                        this.$post(this.$api.wxPayH5Config, this.info).then(postRes => {
                            if (postRes.code == 200) {
                                this.$message.success("编辑成功");
                                this.get_wxpayh5_config();
                            } else {
                                this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            get_wxpayh5_config() {
                this.$get(this.$api.wxPayH5Config).then(res => {
                    if (res.data != null) {
                        this.info = res.data;
                    }
                });
            },
            handleAvatarSuccess(res) {
                this.info.kefwx = res.data;
                this.$forceUpdate();
            },
        },
        created() {
            this.get_wxpayh5_config();
            this.upload_headers.Authorization = 'Bearer ' + localStorage.getItem('token'); // 要保证取到
        },
        mounted() {
        }
    };
</script>
<style lang="scss" scoped>

</style>
