import { render, staticRenderFns } from "./dailzm.vue?vue&type=template&id=32874dda&scoped=true&"
import script from "./dailzm.vue?vue&type=script&lang=js&"
export * from "./dailzm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32874dda",
  null
  
)

export default component.exports