<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>常用配置</div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-tabs tab-position="left" style="min-height: 200px;">
                    <el-tab-pane label="微信收款码"><douyinEwm></douyinEwm></el-tab-pane>
                    <el-tab-pane label="支付宝收款码"><wxpayH5></wxpayH5></el-tab-pane>
                    <el-tab-pane label="平台码"><wxpayApp></wxpayApp></el-tab-pane>
                    <!--<el-tab-pane label="数字人"><wxpayJsApi></wxpayJsApi></el-tab-pane>
                    <el-tab-pane label="美团直播"><wxpayMini></wxpayMini></el-tab-pane>
                    <el-tab-pane label="支付宝直播"><alipayH5></alipayH5></el-tab-pane>
                    <el-tab-pane label="运营专员"><alipayApp></alipayApp></el-tab-pane>
                    <el-tab-pane label="精英社群"><alipayPc></alipayPc></el-tab-pane>
                    <el-tab-pane label="运营经理"><yunyjl></yunyjl></el-tab-pane>
                    <el-tab-pane label="代理招募"><dailzm></dailzm></el-tab-pane>
                    <el-tab-pane label="推广赚钱"><tuigzq></tuigzq></el-tab-pane>
-->





                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import wxpayH5 from '@/components/admin/pay/wxpay_h5'
import wxpayApp from '@/components/admin/pay/wxpay_app'
import wxpayJsApi from '@/components/admin/pay/wxpay_jsapi'
import wxpayMini from '@/components/admin/pay/wxpay_mini'
import alipayH5 from '@/components/admin/pay/alipay_h5'
import alipayPc from '@/components/admin/pay/alipay_pc'
import douyinEwm from '@/components/admin/pay/douyin_ewm'
import alipayApp from '@/components/admin/pay/alipay_app'
import yunyjl from '@/components/admin/pay/yunyjl'
import dailzm from '@/components/admin/pay/dailzm'
import tuigzq from '@/components/admin/pay/tuigzq'



/*
import one from '@/components/admin/pay/one'
import two from '@/components/admin/pay/two'
import three from '@/components/admin/pay/three'
import four from '@/components/admin/pay/four'
import five from '@/components/admin/pay/five'
import six from '@/components/admin/pay/six'
import seven from '@/components/admin/pay/seven'
import eight from '@/components/admin/pay/eight'
import nine from '@/components/admin/pay/nine'
import ten from '@/components/admin/pay/ten'
import eleven from '@/components/admin/pay/eleven'
import twelve from '@/components/admin/pay/twelve'
import thirteen from '@/components/admin/pay/thirteen'
import fourteen from '@/components/admin/pay/fourteen'
import fifteen from '@/components/admin/pay/fifteen'
import sixteen from '@/components/admin/pay/sixteen'
import seventeen from '@/components/admin/pay/seventeen'
*/




export default {
    components: {
        wxpayH5,
        wxpayApp,
        wxpayJsApi,
        wxpayMini,
        alipayH5,
        alipayPc,
        alipayApp,
        douyinEwm,
        yunyjl,
        dailzm,
        tuigzq,


/*        one,
        two,
        three,
        four,
        five,
        six,
        seven,
        eight,
        nine,
        ten,
        eleven,
        twelve,
        thirteen,
        fourteen,
        fifteen,
        sixteen,
        seventeen*/


    },
    props: {},
    data() {
      return {
      };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>
